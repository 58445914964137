<template>
  <v-footer class="window">
    <v-container>
      <v-row class="justify-space-between">
        <v-col cols="3" md="5" id="contacts">
          <div class="text-h5">Наши контакты</div>
          <div
            class="light-green accent-4 mt-2 mb-4"
            style="height: 2px; width: 30%"
          ></div>
          <div>
            По вопросам возврата и обмена услуг, а также возврата денежных
            средств, Вы можете обратиться к нам по следующим контактам:
          </div>
          <div class="ml-16 my-4">
            {{ $store.state.company.Name }}
          </div>
          <div>
            <a
              class="text-decoration-none"
              :href="$store.state.company.AddresLink"
              target="_blank"
            >
              <v-row>
                <v-col sm="1">
                  <v-icon color="orange" large>la-map-marker</v-icon>
                </v-col>
                <v-col>
                  {{ $store.state.company.Addres }}
                </v-col>
              </v-row>
            </a>
          </div>
          <div>
            <a
              class="text-decoration-none"
              :href="$store.state.company.PhoneLink"
            >
              <v-row>
                <v-col sm="1">
                  <v-icon color="orange" large>la-phone</v-icon>
                </v-col>
                <v-col>{{ $store.state.company.Phone }}</v-col>
              </v-row>
            </a>
          </div>
          <div>
            <a
              class="text-decoration-none"
              :href="$store.state.company.EmailLink"
            >
              <v-row>
                <v-col sm="1"
                  ><v-icon color="orange" class="mr-3" large
                    >la-envelope-open</v-icon
                  ></v-col
                >
                <v-col>{{ $store.state.company.Email }}</v-col>
              </v-row>
            </a>
          </div>
        </v-col>
        <v-col cols="2" md="2">
          <div class="text-h5">Наши партнеры</div>
          <div
            class="light-green accent-4 mt-2 mb-8"
            style="height: 2px; width: 80%"
          ></div>
          <div>
            <v-img :src="require('@/assets/tkt-list/TCH_logo_Ru.png')" />
          </div>
          <v-col cols="auto" class="text-center">
            <div>
              <a
                @click="test"
                v-on="on"
                @click.stop
                target="_blank"
                style="color: blue; text-decoration: underline"
              >
                Условия договора и другие важные уведомления
              </a>
            </div>
          </v-col>
        </v-col>
        <v-col cols="5" md="4" id="face">
          <div class="text-h5">Юридическое лицо</div>
          <div
            class="light-green accent-4 mt-2 mb-6"
            style="height: 2px; width: 45%"
          ></div>
          <div class="">
            {{ $store.state.company.Name }}
          </div>
          <div class="my-5">
            <div class="mr-3 text-h8">ИНН: {{ $store.state.company.INN }}</div>
          </div>
          <div class="my-5">
            <div class="mr-3 text-h8">
              ОГРН: {{ $store.state.company.OGRN }}
            </div>
          </div>
          <div class="my-5">
            <div class="mr-3 text-h8">
              Юр. адрес: {{ $store.state.company.FaceAddress }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="text--disabled">
        &copy;ЛИН-ЭЙР {{ new Date().getFullYear() }}
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Validator from "../../mixins/Validators";
import { downloadFile } from "@/mixins/utils.js";

export default {
  name: "MainFooter",
  mixins: [Validator],
  data() {
    return {
      emailNewsletter: "",
    };
  },
  methods: {
    async test() {
      try {
        let resp = await this.$axios.get(this.$enums.Endpoints.Data.TkpPdf);
        let uint8Array = new Uint8Array(
          atob(resp.data)
            .split("")
            .map(function (c) {
              return c.charCodeAt(0);
            })
        );

        downloadFile(uint8Array, {
          name: `Условия договора и другие важные уведомления.pdf`,
        });
      } catch (e) {
        console.error(e);
      }
    },
    subscribe() {
      if (!!this.emailNewsletter && this.validateEmail(this.emailNewsletter)) {
        console.log(this.emailNewsletter);
      }
    },
  },
};
</script>
