import Router from "vue-router";
import Vue from "vue";

import loginer from "@/services/loginer";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      props: (router) => ({ next: router.query.next }),
      component: () => import("@/Pages/Login"),
      meta: { freeAccess: true },
    },
    {
      path: "/",
      name: "home",
      component: () => import("@/Pages/Index"),
      meta: { freeAccess: false },
      alias: "/home",
    },
    {
      path: "/legalInformation",
      name: "legalInformation",
      component: () => import("@/Pages/LegalInformation.vue"),
    },
    {
      path: "/solded-tickets",
      name: "solded-tickets",
      component: () => import("@/Pages/SoldedTickets"),
      props: (router) => ({
        text: router.query.text,
        minPrice: router.query.minPrice,
        maxPrice: router.query.maxPrice,
        orderStatus: router.query.orderStatus,
        provider: router.query.provider,
        page: router.query.page,
        take: router.query.take,
        type: router.query.type,
      }),
      meta: { freeAccess: false },
    },
    {
      path: "/find-tkt",
      name: "find-tkt",
      component: () => import("@/Pages/FindTkt"),
      meta: { freeAccess: false, keepAlive: true },
    },
    {
      path: "/buy-ticket",
      name: "buy-ticket",
      component: () => import("@/Pages/BuyTicket"),
      props: (router) => ({
        adt: router.query.adt,
        cnn: router.query.cnn,
        inf: router.query.inf,
        provider: router.query.provider,
        id: router.query.id,
      }),
      meta: { freeAccess: false },
    },
    {
      path: "/found-tickets",
      name: "found-tickets",
      component: () => import("@/Pages/FoundTickets"),
      props: (router) => ({
        cls: router.query.cls,
        src: router.query.src,
        dest: router.query.dest,
        date: router.query.date,
        back: router.query.back,
        adults: router.query.adults,
        kids: router.query.kids,
        bbs: router.query.bbs,
        direct: router.query.direct,
        city1: router.query.city1,
        city2: router.query.city2,
      }),
      meta: { freeAccess: false, keepAlive: false },
    },
  ],
});
router.beforeEach(async (to, from, next) => {
  if (to.meta.freeAccess) {
    next();
  } else {
    var condation = false;
    try {
      condation = await loginer.checkToken();
    } catch {
      condation = false;
    }
    if (condation) {
      next();
    } else {
      // попробовать
      //next({ name: 'login', query: { next: to.fullPath } });
      next("/login" + `?next=${encodeURIComponent(to.name)}`);
    }
  }
});

export default router;
