import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import enums from "./enums";

import { toBool } from "./support";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    price: 0,
    seats: 0,
    trainSegments: [] | undefined,
    token: "",
    api:
      process.env.NODE_ENV === "development"
        ? "https://localhost:44395/api"
        : "https://linairback.lin-air.ru/api",

    apiAvia:
      process.env.NODE_ENV === "development"
        ? "https://localhost:7011/api"
        : "https://linairback.lin-air.ru/api",

    kktUrl: "http://localhost:8010/proxy/api/v2/requests",
    shiftOpen: localStorage.getItem("shiftOpen") || "",
    shiftExpiry: localStorage.getItem("shiftExpiry") || "",

    company: {
      Name: "ООО «ЛИН-ЭЙР»",
      Phone: "+7 915 466-25-17",
      PhoneLink: "tel:+79154662517",
      INN: "7714458705",
      OGRN: "1207700087154",
      FaceAddress: "109004, г. Москва, ВН.ТЕР. Г. Муниципальный округ Таганский, ул. Николоямская, д. 43, к 4, помещ. 5/9",
      Addres: "101000, Россия, г. Москва, Лубянский пр-д, д. 27/1, стр. 1, 5 этаж, комн. 59",
      AddresLink: "https://2gis.ru/moscow/search/101000%2C%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F%2C%20%D0%B3.%20%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0%2C%20%D0%9B%D1%83%D0%B1%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D0%BF%D1%80-%D0%B4%2C%20%D0%B4.%2027%2F1%2C%20%D1%81%D1%82%D1%80.%201%2C%205%20%D1%8D%D1%82%D0%B0%D0%B6%2C%20%D0%BA%D0%BE%D0%BC%D0%BD.%2059?m=37.636318%2C55.754193%2F17.87",
      Email: "l.eir@yandex.ru",
      EmailLink: "mailto:l.eir@yandex.ru",
      Support: "+79154662517",
    },

    // dialog confirm
    confirmDialogOpen: false,
    additionalMessage: null,
    acceptAction: () => {},
    cancelAction: () => {
      this.confirmDialogOpen = false;
    },

    // Error
    error: {
      show: false,
      value: {
        Code: "",
        Message: "",
      },
    },

    // Snackbar notification
    snackbar: {
      show: false,
      message: "",
      color: "",
      timeout: 5000,
      contentClass: "",
    },
  },

  mutations: {
    addTrainSegment(state, newSegment) {
      state.trainSegments = newSegment;
    },
    removeTrainSegment(state, index) {
      state.trainSegments.splice(index, 1);
    },
    clearTrainSegments(state) {
      state.trainSegments = [];
    },
    addPrice(state, price) {
      state.price = price;
    },
    addSeats(state, seats) {
      state.seats += seats; // Update total price
    },
    clearPrice(state, price) {
      state.price = 0;
    },
    updateBookingData(state, newData) {
      state.bookingData = newData;
    },
    // Screen
    setScreenIsActive(state, value) {
      localStorage.setItem("screen-active", value);
      state.screen.isActive = value;
    },
    setScreenType(state, value) {
      localStorage.setItem("screen-type", value);
      state.screen.type = value;
    },

    setScreenPrice(state, value) {
      var str = JSON.stringify(value);
      localStorage.setItem("screen-price", str);
      state.screen.price = value;
    },

    setToken(state, token) {
      localStorage.setItem("Token", token);
      state.token = token;
      axios.defaults.headers.common["Authorization"] = "bearer " + token;
    },
    setUser(state, user) {
      state.user = { ...user };
    },
    openDialog(state) {
      state.confirmDialogOpen = true;
    },
    closeDialog(state) {
      state.confirmDialogOpen = false;
    },
    setAccept(state, accept) {
      state.acceptAction = () => {
        accept();
        this.commit("closeDialog");
      };
    },
    setSnackbar(state, snack) {
      state.snackbar = snack;
    },
    setCancel(state, cancel) {
      state.cancelAction = () => {
        cancel();
        this.commit("closeDialog");
      };
    },
    setMessage(state, msg) {
      state.additionalMessage = msg;
    },
  },
  actions: {
    addPrice({ commit }, price) {
      commit("addPrice", price);
    },
    addSeats({ commit }, seats) {
      commit("addSeats", seats);
    },
    clearPrice({ commit }) {
      commit("clearPrice");
    },
    addTrainSegment({ commit, state }, newSegment) {
      commit("addTrainSegment", newSegment);
      console.log("action");
      console.log(this.trainSegments);
      if (state.trainSegments.length > 0) {
      }
    },
    removeTrainSegment({ commit, state }, index) {
      commit("removeTrainSegment", index);

      if (state.trainSegments.length === 0) {
      }
    },
    clearTrainSegments({ commit }) {
      commit("clearTrainSegments");
    },
    logout() {
      // Где она блядь?!
      localStorage.removeItem("Token");
      delete axios.defaults.headers.common["Authorization"];
      this.commit("setUser", null);
    },
    setActions({ commit }, { accept, cancel = () => {} }) {
      commit("setAccept", accept);
      commit("setCancel", cancel);
    },
    async updateToken() {
      console.log("updateToken");
      console.log("Обновляемый токен " + this.state.token);

      let response = await axios.get(
        `/Authorization/UpdateToken?token=${this.state.token}`
      );
      console.log(response);
      console.warn("Обновлен токен - " + response.data);
      this.commit("setToken", response.data);
    },
    async initializeScreen() {
      this.state.screen.isActive = await this.dispatch("getScreenIsActive");
      this.state.screen.price = await this.dispatch("getScreenPrice");
      this.state.screen.type = await this.dispatch("getCurrentScreenType");
    },
    async getScreenIsActive() {
      return toBool(localStorage.getItem("screen-active"));
    },
    async getCurrentScreenType() {
      return localStorage.getItem("screen-type");
    },
    async getScreenPrice() {
      return loadJSObject("screen-price");
    },

    async updateBallance() {
      if (!this.state.user.accountSetting.useBallance) return;
      let response = await axios.get(enums.Endpoints.Data.Ballance);
      this.state.user.accountSetting.ballance =
        response.data ?? this.state.user.accountSetting.ballance;
    },
  },

  getters: {
    api: (state) => state.apiAvia,
    token: (state) => state.token,
    user: (state) => state.user,
    getTrainSegments: (state) => () => state.trainSegments,
    getPrice: (state) => () => state.price,
    getSeats: (state) => () => state.seats,
  },
});

const loadJSObject = (key) => {
  const str = localStorage.getItem(key);
  if (!str || str.length == 0) return null;
  return JSON.parse(str);
};
